import React from 'react';
import {
	Grid,
	Container,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import travelG from '../../assets/images/travelG.svg';
import personal from '../../assets/images/personal.svg';
import loca from '../../assets/images/location.svg';
import tour from '../../assets/images/tour.png';
import banner from '../../assets/images/banner.png';
import hol from '../../assets/images/hol.webp';
import { useNavigate } from 'react-router-dom';

const ExploreWorld = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	return (
		<div style={{ marginTop: 50 }}>
			<Container>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={4}>
						<img
							src={hol}
							style={{ height: '100%', borderRadius: 5, width: '100%' }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
						style={{ textAlign: matches ? 'center' : '' }}>
						<span
							title='Explore the world'
							style={{
								color: 'rgb(253 121 14) ',
								fontSize: 18,
								fontWeight: '500',
							}}>
							Explore the world
						</span>
						<h1
							style={{
								fontSize: matches ? 38 : 50,
								//
								fontWeight: 700,
							}}>
							Customized Your FREE AI Powered
							<span style={{ color: 'rgba(182, 194, 212, 1)', marginLeft: 10 }}>
								Holiday Plan
							</span>
						</h1>
						<p
							style={{
								fontSize: 16,
								color: 'rgba(79, 84, 90, 1)',
								marginTop: matches ? 20 : '',
							}}>
							Unlock the secret to your dream destination with our revolutionary
							AI-generated holiday planner, offering a tailored tour experience
							just for you!
						</p>
						<Button
							onClick={() => navigate('/holidaymentor')}
							style={{
								background: 'rgb(253, 121, 14)',
								color: 'white',
								width: '50%',
								marginTop: '20px',
								padding: '15px',
								borderRadius: 30,
							}}>
							Get Started
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ExploreWorld;
