import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import ExploreWorld from '../components/resuableComponents/ExploreWorld';
import Activities from '../components/resuableComponents/Activities';
import NewSlider from '../components/resuableComponents/NewSlider';
import TopDest from '../components/resuableComponents/TopDest';
import BottomBanner from '../components/resuableComponents/BottomBanner';
import Package from '../components/resuableComponents/Package';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAddBookingData,
	setBookingONWARDData,
	setBookingOnewayData,
	setBookingRETURNData,
	setFormData,
	setSelectedHeaderTab,
} from '../reducers/UiReducer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import {
	showtourdestDispatch,
	showBannerDispatch,
} from '../reducers/HomeReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import { walletBalanceAgentDispatch } from '../reducers/AgentReducer';
import Bg from '../assets/images/Bg01.jpg';
import Hotel from './Hotel';
import FeedbackCarousel from '../components/resuableComponents/FeedbackCarousel';
import { Box } from '@mui/material';
import { Divider } from 'antd';

const FlightHomePage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { loading, agentInfo } = useSelector((state) => state.agent);
	const { banner, tourdest } = useSelector((state) => state.home);
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(showtourdestDispatch());
		dispatch(showBannerDispatch());
		sessionStorage.removeItem('bookingDetailRETURN');
		sessionStorage.removeItem('bookingDetailONWARD');
		sessionStorage.removeItem('bookingDataONWARD');
		sessionStorage.removeItem('bookingDataRETURN');
		sessionStorage.removeItem('addBookingData');
		sessionStorage.removeItem('formData');
		dispatch(setBookingRETURNData(null));
		dispatch(setBookingONWARDData(null));
		dispatch(setAddBookingData(null));
		dispatch(setBookingOnewayData(null));
		dispatch(setFormData(null));
		dispatch(
			walletBalanceAgentDispatch(
				agentInfo?.result?.result?.email,
				agentInfo?.result?.result?.id
			)
		);
		if (location.pathname === '/flight') {
			dispatch(setSelectedHeaderTab('flight'));
		}
	}, []);
	return (
		<>
			<div
				style={{
					backgroundImage: `url(${banner?.result[0]?.imgpath})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					height: '600px',
					width: '100%',
				}}>
				<Navbar />
				<NewSlider />
			</div>
			<ExploreWorld />
			<Divider />
			<Package />
			<Divider />
			<Hotel />
			<Divider />
			{/* <Activities /> */}
			{/* <TopDest /> */}
			{/* <BottomBanner /> */}
			<Box mb={'100px'} display={'flex'} justifyContent={'center'}>
				<FeedbackCarousel />
			</Box>
			<Footer />
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default FlightHomePage;
