import React, { useState, useEffect } from 'react';
import './FeedbackCarousel.css'; // Assuming you have converted the CSS

const feedbackData = [
	{
		id: 1,
		image:
			'https://i.postimg.cc/ydBjdm20/michael-dam-m-EZ3-Po-FGs-k-unsplash-1.jpg',
		name: 'Lisa Redfern',
		text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		rating: 5,
	},
	{
		id: 2,
		image:
			'https://i.postimg.cc/ydBjdm20/michael-dam-m-EZ3-Po-FGs-k-unsplash-1.jpg',
		name: 'Cassi',
		text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		rating: 4,
	},
	{
		id: 3,
		image:
			'https://s3-us-west-2.amazonaws.com/s.cdpn.io/451270/profile/profile-80.jpg',
		name: 'Md Nahidul',
		text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		rating: 5,
	},
];

const FeedbackItem = ({ feedback }) => (
	<div className='feedback-slider-item'>
		<img
			src={feedback.image}
			className='center-block img-circle'
			alt='Customer Feedback'
			style={{ borderRadius: 50 }}
		/>
		<h3 className='customer-name'>{feedback.name}</h3>
		<p>{feedback.text}</p>
		<span className='light-bg customer-rating' data-rating={feedback.rating}>
			{feedback.rating}
			<i className='fa fa-star'></i>
		</span>
	</div>
);

const FeedbackCarousel = () => {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % feedbackData.length);
		}, 3000); // Change slide every 3 seconds

		return () => clearInterval(interval);
	}, []);

	return (
		<section className='customer-feedback'>
			<div className='container text-center'>
				<div className='row'>
					<div className='col-sm-offset-2 col-sm-12'>
						<h2 className='section-title'>What Clients Say</h2>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-offset-3 col-md-12 col-sm-offset-2 col-sm-8'>
						<div className='feedback-slider'>
							<FeedbackItem feedback={feedbackData[currentIndex]} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FeedbackCarousel;
