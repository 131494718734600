import React, { useState } from 'react';
import {
	FaPlane,
	FaHotel,
	FaBus,
	FaSun,
	FaShip,
	FaBinoculars,
} from 'react-icons/fa';
import hotel from '../../assets/icons/hotel.png';
import hotelorange from '../../assets/icons/hotelorange.png';
import flight from '../../assets/icons/flight.png';
import flightorange from '../../assets/icons/flightorange.png';
import holidays from '../../assets/icons/holiday.png';
import holidaysorange from '../../assets/icons/holidayorange.png';
import cruiseblack from '../../assets/icons/cruiseblack.png';
import cruiseorange from '../../assets/icons/cruiseorange.png';
import sightsee from '../../assets/icons/sightseeing.png';
import sightseeorange from '../../assets/icons/sightseeingorange.png';
import visa from '../../assets/icons/visa.png';
import visaorange from '../../assets/icons/visaorange.png';
import insurance from '../../assets/icons/travelblack.png';
import insuranceorange from '../../assets/icons/travelorange.png';
import { useNavigate } from 'react-router-dom';
// import hotelnew from '../../assets/icons/hotelnew.png';

function TravelTabs() {
	const [selectedTab, setSelectedTab] = useState('Flights');
	const navigate = useNavigate();

	const tabs = [
		{ name: 'Flights', icon: flight, active: flightorange, id: 'flight' },
		{ name: 'Hotels', icon: hotel, active: hotelorange, id: 'hotel' },
		{
			name: 'Holidays',
			icon: holidays,
			active: holidaysorange,
			id: 'holidays',
		},
		// { name: 'Cruises', icon: cruiseblack, active: cruiseorange, id: 'cruise' },
		{
			name: 'Sightseeings',
			icon: sightsee,
			active: sightseeorange,
			id: 'sightseeing',
		},
		{ name: 'Visa', icon: visa, active: visaorange, id: 'Insurance' },
		{
			name: 'Insurances',
			icon: insurance,
			active: insuranceorange,
			id: 'Insurance',
		},
	];

	// Base styles for the container and tabs
	const containerStyle = {
		display: 'flex',
		justifyContent: 'space-around',
		border: '2px solid #ddd',
		borderRadius: '8px',
		overflow: 'hidden',
		width: '100%',
		maxWidth: '600px',
		margin: 'auto',
		backgroundColor: '#f9f9f9',
		position: 'absolute',
		top: '10%',
		left: '30%',
		padding: 5,
	};

	const tabStyle = {
		flex: 1,
		padding: '10px 0',
		textAlign: 'center',
		cursor: 'pointer',
		transition: 'background-color 0.3s, color 0.3s',
		display: 'flex',
		justifyContent: 'center',
		// borderRight: '1px solid #ddd',
	};

	const selectedTabStyle = {
		...tabStyle,
		// backgroundColor: '#ff6f00', // Orange color
		// color: '#fff',
	};

	const iconStyle = {
		fontSize: '26px',
	};

	const nameStyle = {
		// marginTop: '5px',
		fontSize: '14px',
		textAlign: 'center',
		cursor: 'pointer',
	};
	const nameStyleactive = {
		...nameStyle,
		color: 'orange',
	};

	return (
		<div style={containerStyle}>
			{tabs.map((tab) => (
				<div
					onClick={() => {
						navigate(`/${tab.id}`);
						setSelectedTab(tab.name);
					}}>
					<div
						key={tab.id}
						// onClick={() => setSelectedTab(tab.name)}
						style={selectedTab === tab.name ? selectedTabStyle : tabStyle}>
						<img
							src={selectedTab === tab.name ? tab.active : tab.icon}
							style={{ height: 45 }}
						/>
					</div>
					<div style={selectedTab === tab.name ? nameStyleactive : nameStyle}>
						{tab.name}
					</div>
				</div>
			))}
		</div>
	);
}

export default TravelTabs;
